.contentWrapper {
  padding: 10px;
}
.actionBtn {
  font-size: 10px !important;
  font-weight: 400;
  margin-left: 5px !important;
  padding: 5px 8px !important;
}
/* changeable section */
.changeable {
  background-color: #fff;
  border-top: 1px solid #d2d6de;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
}
/* filter section */
.filterSec {
  display: flex;
  justify-content: space-between;
}

.filerInputSec {
  display: flex;
  max-height: 40px;
  align-items: center;
}
.selectBox {
  min-width: 150px;
}
.selectItem {
  padding-left: 5px;
}
.searchSec {
  padding: 5px;
  margin-left: 20px;
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid rgba(0, 0, 1, 0.1);
}
.serachSec:focus-within {
  border-color: black;
}
.searchInput {
  border: none;
  width: 80%;
  font-size: 17px;
}
.searchInput::placeholder {
  font-size: 15px;
  color: rgba(0, 0, 1, 0.3);
}
.searchInput:focus-visible {
  outline: none;
}
.searchIcon {
  font-size: 28px;
}
/* action button section */
.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.actionBtn {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}
.actionBtnIcon {
  font-size: 16px;
}
.marginTop {
  margin-top: 4px;
}
.inputLabel {
  font-size: 16px;
  font-weight: 600;
  padding-right: 30px;
}
.submitSection {
  margin: 15px;
  margin-left: 0;
}
.btnAddYear {
  text-align: end;
}
